//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HomeHero from "../components/homeHero";
import { RiQuestionnaireLine } from "react-icons/ri";
import { motion } from "framer-motion";

function FAQ({ data }) {
	let faqPage = data.faqPage.edges[0].node.frontmatter;
	let faqContent = data.faqContent.edges;
	console.log(data);
	return (
		<Layout>
			<SEO
				keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
				title="FAQ"
			/>
			<HomeHero
				headline={faqPage.faqBannerHeadline}
				copy={faqPage.faqBannerHeadText}
				phone={faqPage.faqBannerPhone}
				image={faqPage.faqBannerImage.childImageSharp.fluid}
			/>
			<div className="bg-white">
				<div className="mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
				<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.2,
									},
								},
							}}
						>
					<h2 className="text-3xl leading-9 font-extrabold text-gray-900">
						<RiQuestionnaireLine style={{ display: "inline" }} />
						<span style={{ display: "inline", marginLeft: "5px" }}>
							Frequently Asked Questions
						</span>
					</h2>
					</motion.div>
					<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.4,
									},
								},
							}}
						>
					<div className="">
						<dl className="md:grid md:grid-cols-2 md:gap-8">
							{faqContent.map((question, i) => (
								<div key={i} className="mt-12">
									<dt className="text-lg leading-6 font-medium text-gray-900">
										{question.node.frontmatter.title}
									</dt>
									<dd className="mt-2">
										<p
											dangerouslySetInnerHTML={{ __html: question.node.html }}
											className="text-base leading-6 text-gray-600 font-light"
										></p>
									</dd>
								</div>
							))}
						</dl>
					</div>
					</motion.div>
				</div>
			</div>
		</Layout>
	);
}

export default FAQ;

export const faqQuery = graphql`
	query {
		faqContent: allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "faq" }, title: {} } }
			sort: { order: DESC, fields: frontmatter___date }
		) {
			edges {
				node {
					frontmatter {
						title
						date
					}
					rawMarkdownBody
					html
				}
			}
		}
		faqPage: allMarkdownRemark(
			filter: { frontmatter: { pageName: { eq: "faq" } } }
		) {
			edges {
				node {
					frontmatter {
						faqBannerHeadline
						faqBannerHeadText
						faqBannerPhone
						faqBannerImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
					}
					rawMarkdownBody
					id
					html
				}
			}
		}
	}
`;
